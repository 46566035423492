<template>
    <section class="guidance-page">
        <guidance-header class="guidance-header"></guidance-header>
        <div class="guidance-bg">
            <div class="guidance-bg-title">
                <p class="guidance-bg-main-desc">{{$t('guidance.main-title')}}</p>
                <p class="guidance-bg-sub-desc">{{$t('guidance.sub-title')}}</p>
            </div>
        </div>
        <guidance-article class="guidance-art"></guidance-article>
        <guidance-footer></guidance-footer>
    </section>
</template>

<script>
import GuidanceHeader from './../../../components/header.vue'
import GuidanceFooter from './../../../components/footer.vue'
import GuidanceArticle from './article.vue'
export default {
    components: {
        GuidanceHeader,
        GuidanceFooter,
        GuidanceArticle
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped> 
.guidance-page {
    position: relative;
    height: 100%;
    background: #F5F8FA;
    overflow-y: scroll;
}
.guidance-bg {
    height: 312px;
    width: 100%;
    background: url('~@/assets/banner.png') no-repeat center;
    
    overflow: hidden;
}
@media screen and (max-width: 1800px) {
    .guidance-bg {
        background-size: auto 312px;
    }
}
@media  (min-width: 1800px) {
    .guidance-bg {
        background-size: 100% auto;
    }
}
@media  (min-width: 1920px) {
    .guidance-bg {
        background-image: url('~@/assets/banner-x2.png');
    }
}
.guidance-header {
    position: absolute;
    top: 0;
    width: 100%;
}
.guidance-art {
    width: 66%;
    margin: -32px auto 0;
}
.guidance-bg-title {
    width: 66%;
    margin: 122px auto 0;
    color: #FFFFFF;
    text-align: left;
}
.guidance-bg-main-desc {
    font-size: 28px;
    margin-bottom: 14px;
}
.guidance-bg-sub-desc {
    font-size: 14px;
}
</style>