<template>
    <section class="guidance-article">
        <p class="guidance-chapter-title">{{$t('guidance.article-title')}}</p>
        <p class="guidance-tips"><img width="18px" height="18px" src="@/assets/tips.png"> {{$t('guidance.article-tips')}}</p>   
        <img width="411px" style="margin-top: 20px;margin-bottom: 72px" :src="require(`@/assets/${imgSrcPath}/guidance-1.png`)">
        <div v-if="isCnEnv">
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-1-title')}}</p>
            <p class="guidance-main-text magin-top-27">{{$t('guidance.article-chapter-1-text-1')}}</p>
            <div class="guidance-line-card">
                <div class="guidance-line-item">{{$t('guidance.article-chapter-1-text-2')}}</div>
                <div class="guidance-line-item">{{$t('guidance.article-chapter-1-text-3')}}</div>
            </div>
            <p class="guidance-main-text magin-top-27">{{$t('guidance.article-chapter-1-text-4')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-2.png`)">
            <p class="guidance-main-text ">{{$t('guidance.article-chapter-1-text-5')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 72px" :src="require(`@/assets/${imgSrcPath}/guidance-3.png`)">
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-2-title')}}</p>
            <p class="guidance-main-text magin-top-27">{{$t('guidance.article-chapter-2-text-1')}}</p>
            <div class="guidance-line-card horizontal-card">
                <div class="guidance-line-item">{{$t('guidance.article-chapter-2-text-2')}}</div>
                <div class="guidance-line-item">{{$t('guidance.article-chapter-2-text-3')}}</div>
                <div class="guidance-line-item">{{$t('guidance.article-chapter-2-text-4')}}</div>
                <div class="guidance-line-item">{{$t('guidance.article-chapter-2-text-5')}}</div>
            </div>
            <p class="guidance-desc-text " style="margin-bottom: 72px">{{$t('guidance.article-chapter-2-text-6')}}</p>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-3-title')}}</p>
            <p class="guidance-desc-text top-18">{{$t('guidance.article-chapter-3-text-1')}}</p>
            <p class="guidance-desc-text " style="margin-bottom: 72px">{{$t('guidance.article-chapter-3-text-2')}}</p>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-4-title')}}</p>
            <p class="guidance-desc-text top-18">{{$t('guidance.article-chapter-4-text-1')}}</p>
            <p class="guidance-main-text magin-top-27">{{$t('guidance.article-chapter-4-text-2')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-4.png`)">
            <p class="guidance-main-text  bottom-10">{{$t('guidance.article-chapter-4-text-3')}}</p>
            <p class="guidance-main-text bottom-10">{{$t('guidance.article-chapter-4-text-4')}}</p>
            <p class="guidance-main-text ">{{$t('guidance.article-chapter-4-text-5')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 72px" :src="require(`@/assets/${imgSrcPath}/guidance-5.png`)">
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-5-title')}}</p>
            <p class="guidance-desc-text top-18">{{$t('guidance.article-chapter-5-text-1')}}</p>
            <p class="guidance-main-text magin-top-27">{{$t('guidance.article-chapter-5-text-2')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-6.png`)">
            <p class="guidance-main-text ">{{$t('guidance.article-chapter-5-text-3')}}</p>
            <p class="guidance-main-text top-18">{{$t('guidance.article-chapter-5-text-4')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 72px" :src="require(`@/assets/${imgSrcPath}/guidance-7.png`)">
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-6-title')}}</p>
        </div>
        <div v-else>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-1-title')}}</p>
            <p class="guidance-desc-text magin-top-27 bottom-51" style="margin-bottom: 72px">{{$t('guidance.article-chapter-1-text-1')}}</p>
            <p class="guidance-chapter-title ">{{$t('guidance.article-chapter-2-title')}}</p>
            <p class="guidance-main-text magin-top-27 ">{{$t('guidance.article-chapter-2-text-1')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" width="400px" :src="require(`@/assets/${imgSrcPath}/guidance-2.png`)">
            <p class="guidance-main-text  ">{{$t('guidance.article-chapter-2-text-2')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" width="600px" :src="require(`@/assets/${imgSrcPath}/guidance-3.png`)">
            <p class="guidance-main-text  ">{{$t('guidance.article-chapter-2-text-3')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-4.png`)">
            <p class="guidance-main-text  bottom-51" style="margin-bottom: 72px">{{$t('guidance.article-chapter-2-text-4')}}</p>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-3-title')}}</p>
            <p class="guidance-desc-text magin-top-27 ">{{$t('guidance.article-chapter-3-text-1')}}</p>
            <p class="guidance-desc-text top-18 bottom-51" style="margin-bottom: 72px">{{$t('guidance.article-chapter-3-text-2')}}</p>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-4-title')}}</p>
            <p class="guidance-desc-text magin-top-27 ">{{$t('guidance.article-chapter-4-text-1')}}</p>
            <p class="guidance-main-text top-18 ">{{$t('guidance.article-chapter-4-text-2')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-5.png`)">
            <p class="guidance-desc-text  ">{{$t('guidance.article-chapter-4-text-3')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-6.png`)">
            <p class="guidance-main-text  ">{{$t('guidance.article-chapter-4-text-4')}}</p>
            <img  style="margin-top: 20px;margin-bottom: 43px" :src="require(`@/assets/${imgSrcPath}/guidance-7.png`)">
            <p class="guidance-main-text  bottom-51" style="margin-bottom: 72px">{{$t('guidance.article-chapter-4-text-5')}}</p>
            <p class="guidance-chapter-title">{{$t('guidance.article-chapter-5-title')}}</p>
            <p class="guidance-desc-text magin-top-27 ">{{$t('guidance.article-chapter-5-text-1')}}</p>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        isCnEnv() {
            return  this.$i18n.locale === 'cn' || this.$i18n.locale === 't-cn'
        },
        imgSrcPath() {
            const path = this.isCnEnv   ? 'cn' : 'en'
            return path
        }
    }
}
</script>

<style scoped>
img {
    border: 1px solid #e4e6e9;
}
.guidance-article {
    background: white;
    min-height: 400px;
    padding: 43px 31px 68px;
}
.guidance-chapter-title {
    position: relative;
    font-size: 24px;
    text-align: left;
    color: #222222;
    font-weight: bold;
    font-weight: normal;
    line-height: 36px;
}
.guidance-chapter-title::before {
    content: '';
    width: 5px;
    height: 22px;
    background: #0F78E3;
    position: absolute;
    left: -31px;
    display: block;
    top: 7px;
    
}
.guidance-tips {
    font-size: 14px;
    color: #999;
    margin-top: 15px;
}
.guidance-tips img{
    margin-right: 3px;
    vertical-align: sub;
    border: none;
}
.guidance-main-text {
    font-size: 16px;
    color: #222222;
    font-weight: bold;
}
.magin-top-27 {
    margin-top: 27px;
}
.guidance-line-card {
    border: 1px solid #E4E6E9;
    padding: 20px 13px;
    position: relative;
    font-size: 14px;
    color: #222;
    margin:10px 0 20px;
}
.guidance-line-card.horizontal-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.guidance-line-card.horizontal-card .guidance-line-item {
    margin-bottom: 0;
    margin-right: 144px;
}
.guidance-line-card::before {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    display: block;
    background: #0F78E3;
    top: 0;
    
}
.guidance-line-item {
    margin-bottom: 13px;
}
.guidance-line-item:last-of-type {
    margin-bottom: 0;
}
.guidance-line-item::before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: #CDCDCD;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    border-radius: 50%;
    position: relative;
    top: -1px;
}
.guidance-desc-text {
    font-size: 14px;
    color: #222;
    line-height: 24px;
    
}
.bottom-51{
    margin-bottom: 51px;
}
.top-18 {
    margin-top: 18px;
}
.bottom-10 {
    margin-bottom: 10px;
}
</style>