import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import { i18nConfig } from '../../i18n/main-config'
Vue.use(VueI18n)
const savedEnv = localStorage.getItem('env')
const i18n = new VueI18n({
  locale: savedEnv || 'en', // 设置地区
  messages: i18nConfig, // 设置地区信息
})

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
